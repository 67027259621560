const { fromPairs } = require('lodash');

// const { indexOf } = require('lodash');
require('./bootstrap');
require('./vendor/pswmeter');

global.landing = {
    init : function() {
        
        var hash = landing.checkhash();

        $('.modal').modal();

        $('.modal').on('show.bs.modal', function(e) {
            const new_hash = $(e.relatedTarget).data('act') ?? hash;
            window.location.hash = new_hash;
            /*
            if(new_hash == 'dealer-register') {
                $(this).find('.modal-dialog').addClass('modal-lg');
            } else {
                $(this).find('.modal-dialog').removeClass('modal-lg');
            }
            */
            var loading_exist = $(this).find('.modal-body').children('.landing-loading').length;
            $(this).find('.modal-body').children('.landing-form-wrapper').html('').hide(0);

            if(loading_exist > 0) {
                $(this).find('.modal-body').children('.landing-loading').show();
            } else {
                $(this).find('.modal-body').append('<div class="w-100 text-center landing-loading"><img src="/images/loading/loading.gif"></div>');
            }
        });

        $('.modal').on('shown.bs.modal', function (e) {
            const act = $(e.relatedTarget).data('act') ?? hash;
            landing.getlogin(act);
        });
        $('.modal').on('hidden.bs.modal', function (e) {
            $('.landing-alert').remove();
            history.pushState("", document.title, window.location.pathname + window.location.search);
        });

        landing.hashread(hash);

        $(window).on('hashchange', function() {
            var changed_hash = landing.checkhash();
            landing.hashread(changed_hash);
        });

        landing.act_cover_link();
        landing.act_cover_form();
        landing.web_alert_close();

    },
    check_key_press : function(cur_evt) {
		if(cur_evt === "" || typeof cur_evt === "undefined") {
			return false;
		}
		if(cur_evt.ctrlKey) {
			return true;
		}
		else if(cur_evt.altKey) {
			return true;
		}
		else if(cur_evt.shiftKey) {
			return true;
		}
		else if(cur_evt.metaKey) {
			return true;
		} else {
			return false;
		}
	},
    getlogin : function(act) {
        var source = location.protocol + '//' + location.host + '/' + act,
            target = $('.modal-body'),
            token = $('meta[name="token"]').attr('content');

        var jqxhr = $.ajax({
            method: "POST",
            url: source,
            headers: {
                'X-CSRF-TOKEN': token,
            }
        }).done(function(html) {
            _respond = html;
        }).fail(function(err) {
            
            landing.do_cover('remove');
            
            $('.landing-loading').slideUp(function() {
                error_msg = '';
                error_msg = error_msg + '<div class="landing-form-wrapper">';
                error_msg = error_msg + '<span class="p-3 d-block">';
                error_msg = error_msg +     '<h5 class="text-danger d-block mb-4">OH OH ...</h5>';
                error_msg = error_msg +     '<p class="p-0 m-0">Something went wrong and we are not able to process your request at the moment. Please refresh the page and try again. If this issue persist, please contact us.</p>';
                error_msg = error_msg +     '<div class="w-100 d-block mt-4">';
                error_msg = error_msg +         '<button class="btn ps-0" data-bs-dismiss="modal">';
                error_msg = error_msg +             '<svg class="bi" width="20" height="20" fill="currentColor"><use xlink:href="/bootstrap-icons/bootstrap-icons.svg#arrow-left"/></svg>';
                error_msg = error_msg +         '</button>';
                error_msg = error_msg +     '</div>';
                error_msg = error_msg +     '</span>';
                error_msg = error_msg + '</div>';

                target.html(error_msg);
                $('.landing-form-wrapper').slideDown();
            });
            

        }).always(function(_respond) {
            $('.landing-loading').slideUp(function() {
                target.find('.landing-form-wrapper').html(_respond);
                $('.landing-form-wrapper').slideDown();
                if(act == 'dealer-login') landing.reset();
                if(act == 'dealer-register') landing.psstrength();
            });
        });
    },
    hashread : function(hash) {
        if(hash && typeof hash !== 'undefined') {
            $('.modal').modal('toggle');
        } else {
            $('.modal').modal('hide');
        }
    },
    checkhash : function() {
        var hash = (window.location.hash).replace('#', ''),
        hash_let = ['dealer-login','dealer-register'];
        hash = $.inArray(hash, hash_let) >= 0  ? hash : null;
        return hash;
    },
    web_alert : function (type, message, title, btn) {
        var _type = type.toLowerCase(),
            _message = message,
            _title_custom = title,
            _btn_custom = btn;
            _btn_set = '<a href="javascript:void(0);" class="btn btn-light btn-md web-alert-close">Dismiss</a>';
        

        if(_message == '' || typeof _message =='undefined') {
            _message = '...';
        }
        if(_btn_custom != '' && typeof _btn_custom !='undefined') {
            _btn_set = _btn_custom;
        }

        switch(_type) {
            case 'error':
                _class = 'text-danger';
                _icon = 'exclamation-circle';
                _title = 'Error';
                break;
            case 'warning':
                _class = 'text-warning';
                _icon = 'exclamation-diamond';
                _title = 'Warning';
                break;
            case 'success':
                _class = 'text-success';
                _icon = 'check2-all';
                _title = 'Success';
                break;
            case 'info':
                _class = 'text-info';
                _icon = 'info-circle';
                _title = 'Info';
                break;
            default:
                _class = 'text-primary';
                _icon = 'check2-all';
                _title = '';
            }
        _title = (_title_custom != '' && typeof(_title_custom) != 'undefined') ? _title_custom : _title;

        var _alert = '',
            _alert = _alert +   '<div class="modal-content alert-card">',
            _alert = _alert +       '<div class="modal-body">',
            _alert = _alert +           '<div class="p-2 d-block">',
            _alert = _alert +               '<h5 class="'+ _class +' d-block mb-3">'+_title+'</h5>',
            _alert = _alert +               '<p class="p-0 m-0">'+ _message +'</p>',
            _alert = _alert +               '<div class="w-100 d-block mt-3">',
            _alert = _alert +                   '<button class="btn ps-0 web-alert-close">',
            _alert = _alert +                       '<svg class="bi" width="20" height="20" fill="currentColor"><use xlink:href="/bootstrap-icons/bootstrap-icons.svg#arrow-left"></use></svg>',
            _alert = _alert +                   '</button>',
            _alert = _alert +               '</div>',
            _alert = _alert +           '</div>',
            _alert = _alert +       '</div>',
            _alert = _alert +   '</div>',
            _alert = _alert +   '<div class="page_cover_alert web-alert-close"></div>';

        $('body').append(_alert);
                            
    },
    web_alert_close : function () {
        $('body').on('click', '.web-alert-close', function() {
            $('.alert-card').fadeOut(300);
            $('.page_cover_alert').fadeOut(300, function() {
                $('.alert-card-comp').remove();
            });
        });
    },
    do_cover : function($option) {
		cover_html = '<div class="page_cover"><div class="spinner-border text-primary" role="status"></div></div>';
		if($option == 'add') {
			$('.page_cover').remove();
			$('body').append(cover_html);
		} else {
			$('.page_cover').remove();
		}
    },
    act_cover_link: function() {
        $('body').on('click', '.act_cover_link', function(e) {
            if(!landing.check_key_press(e)) {
                e.preventDefault();
                var _target = $(this).prop('href');
                landing.do_cover('add');
                window.location.href = _target;
            }
        });
    },
    act_cover_form: function() {
        $('body').on('click', '.act_cover_form', function(e) {
            var _target = $(this).parents('form'),
                _do_process = true;
            _target.find('input:required').each(function() {
                if($(this).val() == '' || typeof $(this).val() == 'undefined') {
                    _do_process = false;
                }
            });
            if(_do_process) {
                landing.do_cover('add');
                _target.trigger('submit');
            } else {
                landing.web_alert('error', 'Please fill out all required field first.');
                //web_alert : function (type, message, title, btn)
            }
            e.preventDefault();
        });
    },
    reset : function() {
        $('.reset-password').on('click', function() {
            var   reset_target = '/password/email',
                    form = $('.login-form'),
                    p_wrapper = $('.input-password-wrapper');

            $('.landing-alert-wrapper').hide(function() {
                $('.landing-alert-wrapper').remove();
            });
            
            if(p_wrapper.is(':visible')) {
                p_wrapper.slideUp(function() {
                    $('.reset-password-note small').toggle();
                });
                form.prop('action', reset_target);
                $('.sub_btn').text('Reset Password');
                $('#input-password').prop('required', false);
            } else {
                p_wrapper.slideDown(function() {
                    $('.reset-password-note small').toggle();
                });
                form.prop('action', '/login');
                $('.sub_btn').text('Login');
                $('#input-password').prop('required', true);
            }
        });
    },
    passwordStrengthMeter : function(opts) {

        // Container Element
        const containerElement = document.getElementById(opts.containerElement.slice(1));
        containerElement.classList.add('password-strength-meter');
    
        // Score Bar
        let scoreBar = document.createElement('div');
        scoreBar.classList.add('password-strength-meter-score');
    
        // Append score bar to container element
        containerElement.appendChild(scoreBar);
    
        // Password input
        const passwordInput = document.getElementById(opts.passwordInput.slice(1))
        let passwordInputValue = ''
        passwordInput.addEventListener('keyup', function() {
            passwordInputValue = this.value
            checkPassword();
        });
    
        // Chosen Min Length
        let pswMinLength = opts.pswMinLength || 8
    
        // Score Message
        let scoreMessage = opts.showMessage ? document.getElementById(opts.messageContainer.slice(1)) : null
        let messagesList = opts.messagesList === undefined ? ['No data', 'Too simple', 'Simple', 'That\'s OK', 'Great password!'] : opts.messagesList
        if (scoreMessage) { scoreMessage.textContent = messagesList[0] || 'No data'}
    
        // Check Password Function
        function checkPassword() {
    
            let score = getScore()
            updateScore(score)
    
        }
    
        // Get Score Function
        function getScore() {
    
            let score = 0;
    
            let regexLower = new RegExp('(?=.*[a-z])');
            let regexUpper = new RegExp('(?=.*[A-Z])');
            let regexDigits = new RegExp('(?=.*[0-9])');
            // For length score print user selection or default value
            let regexLength = new RegExp('(?=.{' + pswMinLength + ',})');
    
            if (passwordInputValue.match(regexLower)) { ++score }
            if (passwordInputValue.match(regexUpper)) { ++score }
            if (passwordInputValue.match(regexDigits)) { ++score }
            if (passwordInputValue.match(regexLength)) { ++score }
    
            if (score === 0 && passwordInputValue.length > 0) { ++score }
    
            return score;
    
        }
    
        // Show Score Function
        function updateScore(score) {

            switch(score) {
                case 1:
                    containerElement.style.opacity = 1;
                    scoreBar.className = 'password-strength-meter-score psms-25'
                    if (scoreMessage) { scoreMessage.textContent = messagesList[1] || 'Too simple' }
                    containerElement.dispatchEvent(new Event('onScore1', { bubbles: true }));
                    break
                case 2:
                    containerElement.style.opacity = 1;
                    scoreBar.className = 'password-strength-meter-score psms-50'
                    if (scoreMessage) { scoreMessage.textContent = messagesList[2] || 'Simple' }
                    containerElement.dispatchEvent(new Event('onScore2', { bubbles: true }));
                    break
                case 3:
                    containerElement.style.opacity = 1;
                    scoreBar.className = 'password-strength-meter-score psms-75'
                    if (scoreMessage) { scoreMessage.textContent = messagesList[3] || 'That\'s OK' }
                    containerElement.dispatchEvent(new Event('onScore3', { bubbles: true }));
                    break
                case 4:
                    containerElement.style.opacity = 1;
                    scoreBar.className = 'password-strength-meter-score psms-100'
                    if (scoreMessage) { scoreMessage.textContent = messagesList[4] || 'Great password!' }
                    containerElement.dispatchEvent(new Event('onScore4', { bubbles: true }));
                    break
                default:
                    containerElement.style.opacity = 0;
                    scoreBar.className = 'password-strength-meter-score'
                    if (scoreMessage) { scoreMessage.textContent = messagesList[0] || 'No data' }
                    containerElement.dispatchEvent(new Event('onScore0', { bubbles: true }));
            }
        }
    
        // Return anonymous object with properties
        return {
            containerElement,
            getScore
        }
    
    },
    psstrength : function() {
        const myPassMeter = landing.passwordStrengthMeter({
            containerElement: '#strgt',
            passwordInput: '#input-password',
            showMessage: false,
            messageContainer: '#strgt-msg',
            messagesList: [
                'Write your password...',
                'Easy peasy!',
                'That is a simple one',
                'That is better',
                'Yeah! that password rocks ;)'
            ],
            height: 6,
            borderRadius: 0,
            pswMinLength: 8,
            colorScore1: '#aaa',
            colorScore2: '#aaa',
            colorScore3: '#aaa',
            colorScore4: 'limegreen'
        });
    }
}

$(function() {
    landing.init();
});